<template>
  <div id="aircraftDetail" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div   class="N-Page T-size-36">Seat Layout</div>
        <div v-if="flight.fs_id === 3" class="T-size-20 tag tagStatusActive">Status : {{flight.flightstatus.fs_name}}</div>
        <div v-else-if="flight.fs_id === 4" class="T-size-20 tag tagStatusClose">Status : {{flight.flightstatus.fs_name}}</div>
        <div v-else class="T-size-20 tag tagStatusTakeoff">Status : {{flight.flightstatus.fs_name}}</div>
        <!-- tagStatusActive,tagStatusMaintenance,tagStatusUnactive -->
      </div>
    </div>
    <div id="printJS-form2">
      <v-layout row style="padding-left:30px">
        <v-flex md12 lg12>
          <p class="pl-4">
            Flight No. {{ title }} Total : {{ TotalPassenger }} Passengers
          </p>
          <p class="pl-4">Departure : {{flight.departairport.ap_name}} {{ text }}</p>
          <p>
            <span v-for="l in flight.leg" :key="l.Id" class="pl-4">
              {{ l.AirportName }}</span
            >
          </p>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md6 lg6>
          <v-flex md12 lg12 style="padding-let: 10px">
            <table>
              <tbody class="seatPositionnew">
                <tr>
                  <td colspan="4"><img style="margin:0 !important; padding-left:200px" src="/img/NOSE.png"/></td>
                </tr>
                <br/>
                <tr>
                  <td>
                    <img
                      v-if="passengersSeat[3].Status"
                      src="/img2/red_d.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[3].Status"
                      src="/img2/green_d.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[2].Status"
                      src="/img2/red_c.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[2].Status"
                      src="/img2/green_c.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[1].Status"
                      src="/img2/red_b.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[1].Status"
                      src="/img2/green_b.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[0].Status"
                      src="/img2/red_a.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[0].Status"
                      src="/img2/green_a.png"
                      width="100%"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      v-if="passengersSeat[7].Status"
                      src="/img2/red_h.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[7].Status"
                      src="/img2/green_h.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[6].Status"
                      src="/img2/red_g.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[6].Status"
                      src="/img2/green_g.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[5].Status"
                      src="/img2/red_f.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[5].Status"
                      src="/img2/green_f.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[4].Status"
                      src="/img2/red_e.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[4].Status"
                      src="/img2/green_e.png"
                      width="100%"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      v-if="passengersSeat[11].Status"
                      src="/img2/red_n.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[11].Status"
                      src="/img2/green_n.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[10].Status"
                      src="/img2/red_m.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[10].Status"
                      src="/img2/green_m.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[9].Status"
                      src="/img2/red_l.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[9].Status"
                      src="/img2/green_l.png"
                      width="100%"
                    />
                  </td>
                  <td>
                    <img
                      v-if="passengersSeat[8].Status"
                      src="/img2/red_i.png"
                      width="100%"
                    />
                    <img
                      v-if="!passengersSeat[8].Status"
                      src="/img2/green_i.png"
                      width="100%"
                    />
                  </td>
                </tr>
                <br/>
                <tr>
                  <td colspan="4"><img style="margin:0 !important; padding-left:200px" src="/img/TAIL.png"/></td>
                </tr>
              </tbody>
            </table>

          </v-flex>
        </v-flex>

        <v-flex md6 lg6>

          <div style="padding-top: 15px">
            <ul v-for="p in passengers" :key="p.Id">
              <li v-if="p.p_seat_no != null">
                <p>
                  Seat No {{ p.p_seat_no }} : {{ p.user.u_firstname }}
                  {{ p.user.u_lastname }}
                </p>
              </li>
            </ul>
          </div>
        </v-flex>
      </v-layout>
    </div>

    <p class="text-lg-right">
      <v-icon onclick="printJS('printJS-form2', 'html')">mdi-printer</v-icon>
    </p>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn
          text
          class="ColorTheme-S white--text theme-btn"
          :to="{
            name: backPage,
            params: { mode: 'Edit', id: idflightNow },
          }"
        >
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import print from "print-js";
export default {
  data: () => ({
    //--start config
    service: "flight",

    defaultValue: {},
    query: { $sort: { Id: -1 } },
    //--end config
flight:{},
    items: [],
    loading: false,
    dialog: false,
    formModel: {},
    inDTO: {}, // data ที่มาจากการ get ของ server
    outDTO: {}, // data ที่เป็น Object ที่จะส่งไป create หรือ update ที่ server

    idflightNow: 0,

    TotalPassenger: 0,
    title: "",
    text: "",
    passengers: [],
    passengersSeat: [],
    backPage: "",
  }),
  props: ["mode", "idflight"],
  computed: {},
  async mounted() {
    this.backPage = this.$route.params.mode;
    this.idflightNow = this.$route.params.idflight;
    this.renderUI();
  },
  methods: {
    async renderUI() {
      try {
        var q = {};
        q.f_id = this.idflightNow;
        q.$eager = "[aircrafts,passenger.[user],departairport,leg,flightstatus]";
        {
          Id: this.Id;
        }

        var res = await feathersClientUOA
          .service(this.service)
          .find({ query: q });
        this.flight = res.data[0];
        this.TotalPassenger = this.flight.passenger.filter(
          (x) => x.pt_id == 1 && x.p_seat_no != null
        ).length;
        this.title = this.flight.f_flight_no;

        let legs = "";
        this.flight.leg.forEach((leg) => {
          legs += " => " + leg.ap_name;
        });
        this.text = "Arrive " + legs;

        this.passengers = this.sortedArray();

        let seatNo = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N"];

        for (let i = 0; i < 12; i++) {
          if (
            this.passengers.filter((x) => x.p_seat_no == "A").length > 0 &&
            i == 0
          ) {
            this.passengersSeat.push({ SeatNo: "A", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "B").length > 0 &&
            i == 1
          ) {
            this.passengersSeat.push({ SeatNo: "B", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "C").length > 0 &&
            i == 2
          ) {
            this.passengersSeat.push({ SeatNo: "C", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "D").length > 0 &&
            i == 3
          ) {
            this.passengersSeat.push({ SeatNo: "D", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "E").length > 0 &&
            i == 4
          ) {
            this.passengersSeat.push({ SeatNo: "E", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "F").length > 0 &&
            i == 5
          ) {
            this.passengersSeat.push({ SeatNo: "F", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "G").length > 0 &&
            i == 6
          ) {
            this.passengersSeat.push({ SeatNo: "G", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "H").length > 0 &&
            i == 7
          ) {
            this.passengersSeat.push({ SeatNo: "H", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "I").length > 0 &&
            i == 8
          ) {
            this.passengersSeat.push({ SeatNo: "I", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "L").length > 0 &&
            i == 9
          ) {
            this.passengersSeat.push({ SeatNo: "L", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "M").length > 0 &&
            i == 10
          ) {
            this.passengersSeat.push({ SeatNo: "M", Status: true });
          } else if (
            this.passengers.filter((x) => x.p_seat_no == "N").length > 0 &&
            i == 11
          ) {
            this.passengersSeat.push({ SeatNo: "N", Status: true });
          } else {
            this.passengersSeat.push({ SeatNo: seatNo[i], Status: false });
          }
        }
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    sortedArray() {
      function compare(a, b) {
        if (a.p_seat_no < b.p_seat_no) return -1;
        if (a.p_seat_no > b.p_seat_no) return 1;
        return 0;
      }
      return this.flight.passenger.sort(compare);
    },
  },
};
</script>

<style scoped>
.seatPositionnew img {
  width: 60%;
  height: auto;
  padding: 5px;
  margin: auto;
}

</style>>
